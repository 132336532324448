/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EducatorPlanPage,
.educatorPlanBox {
  background: #f9f6ef;
  padding: 10rem 4rem 4rem;
  position: relative;
}
.EducatorPlanPage .title,
.educatorPlanBox .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
}
@media (max-width: 991px) {
  .EducatorPlanPage .title,
  .educatorPlanBox .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
@media (max-width: 767px) {
  .EducatorPlanPage .title,
  .educatorPlanBox .title {
    margin-top: 20rem;
  }
}
.EducatorPlanPage .subtitle,
.educatorPlanBox .subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #745a9e;
  max-width: 50rem;
  margin: 3rem auto 0;
  text-align: center;
}
.EducatorPlanPage .pipeRight,
.educatorPlanBox .pipeRight {
  position: absolute;
  right: 0;
  top: 5rem;
}
.EducatorPlanPage .Form,
.educatorPlanBox .Form {
  margin: 15rem auto 0;
  max-width: 90rem;
}
.EducatorPlanPage .Form .Input,
.educatorPlanBox .Form .Input {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.EducatorPlanPage .Form div#totalPrice,
.educatorPlanBox .Form div#totalPrice {
  padding-bottom: 1rem;
}
.EducatorPlanPage .Form div#totalPrice p,
.educatorPlanBox .Form div#totalPrice p {
  margin-top: 0;
}
.EducatorPlanPage p,
.educatorPlanBox p {
  font-weight: 600;
  font-size: 2rem;
  margin: 2rem auto 0;
  max-width: 65rem;
}
